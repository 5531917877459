import React, { useState } from "react";
import "./modal.css";
import data from "../../utils.js/data.json";
import { Link } from "react-router-dom";
import logo from "../../utils.js/imgs/logos/logo_y.png";

const Modal = ({ showModal, setShowModal }) => {
  
  const [title] = useState(data?.servizi?.[showModal?.id]?.serviceName);

  const descModalStyle = () => {
    return { top: window.pageYOffset };
  };

  const actualModalClass = () => {
    switch (showModal?.type) {
      case "lavora_con_noi":
        return "_lavora_con_noi";
      default:
        return "";
    }
  };

  const handleTitleClass = () => {
    switch (showModal?.type) {
      case "lavora_con_noi":
      case "servizi":
        return "title_lcn";
      default:
        return "title_prev";
    }
  };

  const lavoraConNoiContent = () => {
    return (
      <div className="lavoraConNoi">
        <p>
          Da sempre le persone sono la vera forza della nostra azienda e siamo
          sempre alla ricerca di professionisti in grado di valorizzare e
          migliorare i nostri risultati.
        </p>

        <p className="mailto">
          Entra a far parte del nostro team, invia la tua candidatura alla
          mail&nbsp;
          <a href="mailto:acsistems@gmail.com">acsistems@gmail.com</a>
        </p>

        <p>Cosa inserire nella mail:</p>

        <ul>
          <li>campo per il quale ci si candida (anche più di uno)</li>
          <li>lettera di presentazione</li>
          <li>CV aggiornato in PDF</li>
        </ul>
      </div>
    );
  };

  const handleHead = () => {
    switch (showModal?.type) {
      case "lavora_con_noi":
        return "LAVORA CON NOI";
      default:
        return title;
    }
  };

  const handleContent = () => {
      switch (showModal?.type) {
          case "lavora_con_noi":
              return lavoraConNoiContent();
          default:
              switch (title) {
                  case "FOTOVOLTAICO":
                      return (
                          <p className='content-p-f'>
                              <b>H</b>ai finalmente deciso di produrre da te l’energia per i tuoi consumi giornalieri o della Tua azienda, il tuo riscaldamento o la produzione di acqua sanitaria? <br/><br/>
                              <b>AC SYSTEMS</b> si occuperà del fotovoltaico industriale o civile calcolato sulle tue esigenze con prodotti di varie fasce di efficienza e prezzo. <br/><br/> <b>H</b>ai già un impianto ma non rende come prima? Probabilmente qualche stringa o pannello ha dei problemi o è semplicemente sporco. <br/><br/> <b>C</b>i occupiamo anche del lavaggio con apposita strumentazione e valutiamo insieme la necessità di un ampliamento. Se invece non conosci la resa del tuo impianto possiamo installare sistemi di monitoraggio anche da smartphone. <br/><br/> <b>V</b>alutiamo insieme la possibilità di aggiungere al tuo impianto un sistema di accumulo proporzionato ai tuoi pannelli ed alle tue esigenze per utilizzare l’energia da te prodotta anche la sera o in caso si interruzione elettrica.
                          </p>
                      );
                  case "IMPIANTISTICA ELETTRICA":
                      return (
                          <p className='content-p-f'>
                              <b>I</b>l tuo impianto elettrico consuma anche da spento? Dalle prese esce l’acqua? Hai ancora le lampadine a fluorescenza che si accendono piano piano? 
                              <br/><br/>

                              <b>N</b>oi speriamo di no, ma per questi interventi o anche altri più radicali puoi contattarci per valutare insieme l’impiantistica elettrica della tua casa o azienda per riportarla al presente.
                              <br/><br/>
                              
                              <b>N</b>uova costruzione?! Ancora meglio, possiamo consigliarti i prodotti più innovativi dell’illuminazione interna ed esterna ed anche di monitoraggio dei consumi o un sistema domotico.
                          </p>
                      );
                  case "TERMOIDRAULICA":
                      return (
                          <p className='content-p-f'>
                              <b>F</b>are il fuoco al centro di ogni stanza non è più la soluzione! 
                              <br/><br/>
                              <b>D</b>ato il prezzo dei combustibili fossili, la tua vecchia caldaia al giorno d’oggi ha più sete della pianta che ti ha regalato la suocera. Noi di AC Systems possiamo trovare insieme la soluzione migliore per la riqualificazione energetica della tua abitazione o azienda. <br/><br/>
                              <b>C</b>aldaie di ultima generazione, condizionatori con chile aria/aria o aria/acqua, pavimenti radianti e radiatori. Qualsiasi sia la strada scelta, il nostro personale qualificato sarà in grado di accontentare le tue esigenze.
                          </p>
                      );
                  case "ANTINTRUSIONE":
                      return (
                          <p className='content-p-f'>
                              <b>S</b>e il bobotti non è solo nei racconti dei tuoi figli ma è un rischio concreto per la tua casa e la tua azienda, noi di AC systems ci affidiamo ai migliori marchi per la sicurezza.<br/><br/> 
                              <b>C</b>on sistemi di alto livello che spaziano dalle videocamere interne/esterne ai volumetrici ed agli accessi biometrici con impronta digitale. <br/><br/>
                              <b>L</b>a nostra assistenza si estende anche dopo l’istallazione senza abbandonare il cliente.
                          </p>
                      );
                  default:
                      break;
              }
      }
  };

  const handleButton = () => {
    switch (showModal?.type) {
      case "lavora_con_noi":
        return <button onClick={() => closeModal()}>OK</button>;
      default:
        return <button onClick={() => closeModal()}>HO LETTO</button>;
    }
  };

  const closeModal = () => {
    setShowModal({ show: false, type: "", id: null });
    window.onscroll = function () {};
  };

  const generateModalClass = () => {
    switch (title) {
      case "IMPIANTISTICA ELETTRICA":
        return "ie-content";
      case "FOTOVOLTAICO":
        return "fv-content";
      case "TERMOIDRAULICA":
        return "ti-content";
      case "ANTINTRUSIONE":
        return "ai-content";
      default:
        return "lcn";
    }
  };

  return (
    <dialog
      className="descModal"
      onClick={() => closeModal()}
      style={descModalStyle()}
    >
      {showModal?.type !== "preventivo" ? (
        <div className={`actual-modal${actualModalClass()}`}>
          <div className="bar" />

          <div className="top-modal">
            <div className="logo-title">
              <div className="logo-container">
                <img src={logo} alt="logo" />
              </div>
              <h3 className={handleTitleClass()}>{handleHead()}</h3>
            </div>
          </div>

          <div className={generateModalClass()}>{handleContent()}</div>
          <div className="btn_container">{handleButton()}</div>
        </div>
      ) : (
        <div className="modal-prev">
          <div className="bar-prev" />

          <div className="top-modal-prev">
            <div className="logo-title-prev">
              <div className="logo-container-prev">
                <img src={logo} alt="logo" />
              </div>
              <h3 className={handleTitleClass()}>PREVENTIVO EFFETTUATO</h3>
            </div>
          </div>

          <div className="content-prev">
            <p>Grazie, torneremo da te al più presto.</p>
          </div>

          <div className="prev-btn-container">
            <Link to="/">OK</Link>
          </div>
        </div>
      )}
    </dialog>
  );
};

export default Modal;